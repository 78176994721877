import React, { useMemo, useEffect, useRef } from 'react';
import { ImagesListProps, TagPlaceEnum } from '../../features/types/types'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation, Pagination, Virtual } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Tag from '../Tag/Index';
import "./styles.css"
import NotesField from '../NotesField/index';

const ImagesList = ({ quantity, tag, setTags, excelTags, file, uploadedImages, checkImagesTagsNumber, journalistPosition, imagePosition, notes, setNotes, setGenericLoading }: ImagesListProps) => {

  let allJournalistsWithImages = new Array()

  const swiperRef = useRef() as any;

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(imagePosition);
      setGenericLoading(false)
    }
  }, [imagePosition]);

  useEffect(() => {
    if(uploadedImages) setGenericLoading(false)
  }, [uploadedImages])


  const images = useMemo(() => {
    let images = []

    let totalTags: any = []

    if (uploadedImages) {
      for (let i = 0; i < excelTags.length; i++) {
        for (let j = 0; j < uploadedImages.length; j++) {
          const tempTag = tag[(i * uploadedImages.length) + j]
          if (tempTag && tempTag?.length > 0) {
            allJournalistsWithImages.push([tempTag, uploadedImages[j]])
          }
        }
      }

      if (!file || allJournalistsWithImages.length === 0) {
        for (let i = 0; i < uploadedImages.length; i++) {
          images.push(
            <SwiperSlide key={i} style={{ display: "flex", justifyContent: "center" }}>
              <img alt="Lambo-Image" className="imageNoTags" src={uploadedImages[i]} />
            </SwiperSlide>
          )
        }
      } else {
        let totalPosition = journalistPosition > 0 ? journalistPosition*uploadedImages.length : journalistPosition
        for (let i = 0; i < uploadedImages.length; i++) { 
          let new_position = (i+totalPosition)
          totalTags = [...allJournalistsWithImages[new_position][0]]
          images.push(
            <SwiperSlide key={i} className="swiperSlide" virtualIndex={i}>
              <div className='infoWrapper'>
                <div>
                  <h4 style={{ marginTop: "15px", marginBottom: "0px" }}>
                    Journalist {journalistPosition+1} / {excelTags.length / uploadedImages.length} {"-->"} {allJournalistsWithImages[new_position][0][0]}
                  </h4>

                  {uploadedImages.length > 1 ? <h4 style={{ marginTop: "0px" }}>Image {i+1} / {uploadedImages.length}</h4> : null}

                </div>
                {uploadedImages.length > 1 ? <h4>{new_position+1} / total {excelTags.length}</h4> : null}
              </div>
              <img alt="Lambo-Image" className='image' src={allJournalistsWithImages[i][1]} loading="lazy"/>
              <div className='tagsbar'> <Tag tag={totalTags.filter((el: any, index: any) => totalTags.indexOf(el) == index)} setTags={setTags} index={new_position} file={file} excelTags={excelTags}
                TagPlace={TagPlaceEnum.ImageSide} key={`${i+journalistPosition}-${"j"}-${[...(tag[i+journalistPosition] || [])].join(',')}`} uploadedImages={uploadedImages} checkImagesTagsNumber={checkImagesTagsNumber} /> 
              </div>
              <NotesField notes={notes} index={new_position} setNotes={setNotes} key={i+journalistPosition} />
            </SwiperSlide>
          )
        }
      }
    }

    return images
  }, [tag, quantity, excelTags, file, uploadedImages, journalistPosition])

  return (
    (uploadedImages) && <>
      <Swiper
        ref={swiperRef}
        pagination={{
          type: "progressbar"
        }}
        className="swiper"
        navigation={true}
        modules={[Navigation, Pagination, Virtual]}
      >
        {images}
      </Swiper>
    </>
  )
}

export default ImagesList 
