import { LoadFileProps } from '../../features/types/types';
import * as XLSX from 'xlsx';
import React, { CSSProperties, useEffect } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';

const LoadFile = ({ file, setFile, excelTags, setExcelTags, inputId, setQuantity, zippedFolder, setCheckImagesTagsNumber, checkImagesTagsNumber, setNotes }: LoadFileProps) => {
  useEffect(() => {
    setQuantity(0)
    let fileReader = new FileReader();

    fileReader.addEventListener("loadend", (evt) => {
      var workbook = XLSX.read(evt.target?.result, { type: "binary" }),
        worksheet: any = workbook.Sheets[workbook.SheetNames[0]],
        range = XLSX.utils.decode_range(worksheet["!ref"]);

      var data: any = []
      var notes: any = []
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        let exit = false

        let colCell = worksheet[XLSX.utils.encode_cell({ r: row, c: 1 })]
        if (!colCell?.v) {
          exit = true
          break
        }
        if (checkImagesTagsNumber <= parseInt(colCell?.v)) {
          setCheckImagesTagsNumber(parseInt(colCell?.v))
        }
        data[row - 1] = []
        for (let column = 2; column <= 7; column++) {
          let colCell = worksheet[XLSX.utils.encode_cell({ r: row, c: column })]
          if (colCell?.v) {
            if (!data[row - 1]) {

            }
            data[row - 1].push(colCell.v)
          } else {
            continue
          }
        }

        let noteCell = worksheet[XLSX.utils.encode_cell({ r: row, c: 9 })]
        if (!noteCell?.v) { 
          notes.push("")
        } else {
          notes.push(noteCell.v)
        }
      }
      if (excelTags.length === 0) {
        setExcelTags(data)
        setNotes(notes)
      }
    })

    if (!file) {

    } else fileReader.readAsArrayBuffer(file)
  }, [file])

  let disabledButton = (!file && !zippedFolder) || (file)
  return (
    <>
      <div style={{marginBottom: "20px"}}>
        <div>
          {
            <p style={{ color: (!file && !zippedFolder) || (file) ? "#6c757d" : "#FFF", fontSize: 18, marginTop: "0px", fontWeight: "bold", display: "flex", flexDirection: "row" }}>
              Upload excel file to select how many watermarked images to create and import relative tags
            </p>
          }
        </div>
        <div style={styles.container}>
          <ThemeProvider theme={buttonsTheme}>
            <Button variant="outlined" color={disabledButton ? "secondary" : "primary"} component="label" sx={{ height: 36 }}>
              Upload file
              <input
                hidden
                disabled={(!file && !zippedFolder) || (file) ? true : false}
                id={inputId}
                style={styles.InputFile}
                type="file"
                accept=".xls, .xlsx, .ods"
                name="spreadsheet"
                onChange={(event) => setFile((event.target as any).files[0] as any)}
              />
            </Button>
            <a href="example.xlsx" download style={{ color: "#fff", paddingRight: "10px", fontWeight: "bold" }} >Download example excel file</a>
          </ThemeProvider>
        </div>
      </div> 
    </>
  );
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  InputFile: {
    fontSize: 16,
    fontFamily: "Roboto",
  }
}

const buttonsTheme = createTheme({
  palette: {
    primary: {
      main: "#DDB321",
    },
    secondary: {
      main: '#6c757d',
    },
    text: {
      secondary: '#00FF00',
    },
  }
})

export default LoadFile